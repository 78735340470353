* {
  margin: 0;
  padding: 0;

  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 1px;
}

html {
  scroll-behavior: smooth;
}

body {
  min-width: 100%;
  max-width: 100vw;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #707070;
  border-radius: 8px;
}

#root {
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

.container-app {
  width: 90%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column nowrap;
  gap: 24px;

  padding-top: 72px;
  box-sizing: border-box;
}

.app-title {
  width: 100%;
  max-width: 360px;
  font-weight: 600;
}
