.container-password-main {
  display: grid;
  grid-template-areas:
    'input'
    'bars'
    'loading'
    'error'
    'warning'
    'guessTime'
    'suggestions';

  width: 100%;
  max-width: 480px;
  gap: 16px;
}
