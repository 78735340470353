.input {
  flex: 1;
  border: 0;
  outline: 0;
  height: 48px;
}
.button {
  border: 0;
  outline: 0;
  background-color: transparent;
  color: blue;
}
